@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
} 

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

h6 {
  @apply text-sm;
}

::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #F1F1F1; 
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background: #B9DBFD;
  border-radius: 15px; 
}
::-webkit-scrollbar-thumb:hover {
  background: #BBBBBB; 
}